export const driverData = [
  {
    id: "CONQUEROR",
    img: "images/fitting/Conqueror.Webp",
    title: "CONQUEROR",
    link: "Conqueror",
    price: "1,200,000",
    src: "images/gtdProduct/Conqueror",
  },
  {
    id: "B.ICE-DIAMOND_PLATINUM",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM.Webp",
    title: "B.ICE-DIAMOND_PLATINUM",
    link: "B.ICE-DIAMOND_PLATINUM",
    price: "1,600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM",
  },
  {
    id: "B.ICE-DIAMOND_BLACK(M)",
    img: "images/fitting/B.ICE-DIAMOND_BLACK(M).Webp",
    title: "B.ICE-DIAMOND_BLACK(M)",
    link: "B.ICE-DIAMOND_BLACK(M)",
    price: "1,300,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK(M)",
  },
  {
    id: "B.ICE-DIAMOND_BLACK(W)",
    img: "images/fitting/B.ICE-DIAMOND_BLACK(W).Webp",
    title: "B.ICE-DIAMOND_BLACK(W)",
    link: "B.ICE-DIAMOND_BLACK(W)",
    price: "1,300,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK(W)",
  },
  {
    id: "B.ICE-DIAMOND_BLUE",
    img: "images/fitting/B.ICE-DIAMOND_BLUE.Webp",
    title: "B.ICE-DIAMOND_BLUE",
    link: "B.ICE-DIAMOND_BLUE",
    price: "1,300,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE",
  },
  {
    id: "B.ICE-DIAMOND_PINK",
    img: "images/fitting/B.ICE-DIAMOND_PINK.Webp",
    title: "B.ICE-DIAMOND_PINK",
    link: "B.ICE-DIAMOND_PINK",
    price: "1,300,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK",
  },
  {
    id: "B.ICE-DIAMOND_GREEN",
    img: "images/fitting/B.ICE-DIAMOND_GREEN.Webp",
    title: "B.ICE-DIAMOND_GREEN",
    link: "B.ICE-DIAMOND_GREEN",
    price: "1,300,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN",
  },
  {
    id: "blackIce460Alpha",
    img: "images/fitting/blackIce460.Webp",
    title: "BLACK ICE 460 α",
    link: "blackIce460Alpha",
    price: "1,400,000",
    src: "images/gtdProduct/blackIce460",
  },
  {
    id: "blackIce460",
    img: "images/fitting/blackIce460.Webp",
    title: "BLACK ICE 460",
    link: "blackIce460",
    price: "1,200,000",
    src: "images/gtdProduct/blackIce460",
  },
  {
    id: "blackIceTheMax",
    img: "images/fitting/blackIceTheMax.Webp",
    title: "BLACK ICE THE MAX",
    link: "blackIceTheMax",
    price: "1,200,000",
    src: "images/gtdProduct/blackIceTheMax",
  },
  {
    id: "GT455Plus2",
    img: "images/fitting/GT455Plus2.Webp",
    title: "GT 455 Plus 2",
    link: "GT455Plus2",
    price: "1,200,000",
    src: "images/gtdProduct/GT455Plus2",
  },
  {
    id: "GT455Angel-C",
    img: "images/fitting/GT455Angel-C.Webp",
    title: "GT 455 Angel-C",
    link: "GT455Angel-C",
    price: "1,200,000",
    src: "images/gtdProduct/GT455Angel-C",
  },
  {
    id: "GT455Alpha",
    img: "images/fitting/GT455Alpha.Webp",
    title: "GT 455 Alpha",
    link: "GT455Alpha",
    price: "1,300,000",
    src: "images/gtdProduct/GT455Alpha",
  },
];
